import * as React from "react"
import SidebarMenu from "./SidebarMenu"
import { MENU_LINKS } from "../../consts/MenuLinks"

export default function SidebarMenuServices(props) {
  const { setFormPopup, location } = props

  const sidebarList = [
    { name: "Подарочный сертификат", href: MENU_LINKS.certificate },
    { name: "Тренинг", href: MENU_LINKS.training },
    { name: "Promo-video", href: MENU_LINKS.promo },
  ]

  return (
    <SidebarMenu
      location={location}
      setFormPopup={setFormPopup}
      sidebarList={sidebarList}
    />
  )
}

import * as React from "react"
import {useEffect, useRef, useState} from "react"
import { motion } from "framer-motion"
import Slider from "react-slick"

import Img from "react-cool-img"
import {
  SCREEN_WIDTH_DESKTOP,
  SCREEN_WIDTH_TABLET,
} from "../../consts/SizeScreens"
import { STRAPI_API } from "../../consts/api"

// FIXME реализовать слайдер через framer motion
export default function SliderImages(props) {
  const { data, positionCenter, classAdd, setOpen, setGalleryElem, open } =
    props

  const isBrowser = typeof window !== "undefined"

  const slick = useRef(null)

  const galleryData = mapSliderImageData(data)

  let windowWidth = 1000
  if (isBrowser)
    windowWidth = window?.visualViewport
      ? window.visualViewport.width
      : window.innerWidth

  const [currentElem, setCurrentElem] = useState(0)

  const pos = !!positionCenter

  const settings = {
    dots: false,
    infinite: true,
    fade: false,
    arrows: false,
    speed: 400,
    cssEase: "ease-in-out",
    draggable: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    centerMode: pos,
    variableWidth: true,
    slidesToShow: pos ? 1 : 2,
    slidesToScroll: 1,
    afterChange: next => setCurrentElem(next),
    initialSlide: 0,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: SCREEN_WIDTH_TABLET,
        settings: {
          infinite: true,
          centerMode: false,
          draggable: true,
          pauseOnHover: true,
          pauseOnFocus: true,
        },
      },
    ],
  }

  function mapSliderImageData(data) {
    return data.map(item => {
      return { src: STRAPI_API + item.url }
    })
  }

  return (
    <div className={classAdd ? `${classAdd} photoSlider` : "photoSlider"}>
      <div className="sliderImages">
        <div className="sliderImages__cont">
          <Slider
            ref={slick}
            className={`sliderImages__slick ${
              pos ? "sliderImages__slick--center" : ""
            }`}
            {...settings}
          >
            {galleryData.map((element, index) => (
              <motion.div
                whileHover={{ scale: 1.05 }}
                className="sliderImages__slick-box"
                key={index}
              >
                <div className="sliderImages__slick-wrapper">
                  <Img
                    onMouseDown={() => {
                      if (
                        setGalleryElem &&
                        setOpen &&
                        windowWidth < SCREEN_WIDTH_DESKTOP
                      ) {
                        setOpen(!open)
                        setGalleryElem(index)
                      }
                    }}
                    onClick={e => {
                      if (e.target.closest(".slick-active")) {
                        setOpen(!open)
                        setGalleryElem(currentElem)
                      }
                    }}
                    data-gallery_index={index}
                    src={element.src}
                    lazy={false}
                    draggable={false}
                    alt="Слайд из фотографий школы Pioneer DJ School"
                  />
                </div>
              </motion.div>
            ))}
          </Slider>

          {!pos && (
            <div className="sliderButtons">
              <button
                className={
                  currentElem === 0
                    ? "slick-button--disabled slick-button--prev"
                    : "slick-button--prev"
                }
                aria-label={"Предыдущий фотография"}
                onClick={() => {
                  if (currentElem !== 0) slick.current?.slickPrev()
                }}
              />

              <span className="sliderImages__counter">{`${currentElem + 1} / ${
                galleryData.length
              }`}</span>
              <button
                className="slick-button--next"
                aria-label={"Следующая фотография"}
                onClick={() => slick.current?.slickNext()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import * as React from "react"
import { useState } from "react"
import { AnimatePresence } from "framer-motion"
import "./services/certificate/certificate.scss"
import FormPopup from "../components/popups/FormPopup"
import GalleryPopup from "../components/popups/GalleryPopup"
import HelpChoosing from "../components/offers/HelpChoosing"
import SliderImages from "../components/sliders/SliderImages"
import TextGlassCards from "../components/cards/TextGlassCard"
import DoubleBlockHead from "../components/offers/DoubleBlockHead/DoubleBlockHead"
import SidebarMenuServices from "../components/sideBarMenu/SidebarMenuServices"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import {SEO} from "../components/seo";
import VideoBox from "../components/VideoBox";
import {STRAPI_API} from "../consts/api";
import VideoPopup from "../components/popups/VideoPopup";

const Certificate = ({ data, location }) => {
  const { strapiGiftPage, strapiMainPage } = data

  const [formPopup, setFormPopup] = useState(false)

  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  const mapTextData = data => {
    return [
      {
        title: data.topItem.title,
        subtitle: data.topItem.subtitle,
      },
      {
        title: data.bottomItem1.title,
        subtitle: data.bottomItem1.subtitle,
      },
      {
        title: data.bottomItem2.title,
        subtitle: data.bottomItem2.subtitle,
      },
    ]
  }

  return (
    <div>
      <SEO title={strapiGiftPage?.titleDesc?.pageTitle} description={strapiGiftPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="certificatePage" location={location}>
        <DoubleBlock menu={<SidebarMenuServices setFormPopup={setFormPopup} />}>
          {
            // MAIN OFFER
            strapiGiftPage?.offer && (
              <DoubleBlockHead
                setFormPopup={setFormPopup}
                data={strapiGiftPage.offer}
              />
            )
          }

          {
            // TEXT BLOCK
            strapiGiftPage?.topItem &&
              strapiGiftPage?.bottomItem1 &&
              strapiGiftPage?.bottomItem2 && (
                <TextGlassCards
                  classAdd="textCard--one-two textCard--bigText m60_40"
                  data={mapTextData(strapiGiftPage)}
                />
              )
          }

          {
            // VIDEO BOX CARD
              strapiMainPage?.mainVideo?.url && (
                  <div className="m60_40">
                    <VideoBox
                        videSrc={STRAPI_API + strapiMainPage?.mainVideo?.url}
                        coverImg={STRAPI_API + strapiMainPage?.mainPoster?.url}
                        setVideoSrc={setVideoSrc}
                        setPopUpVideo={setPopUpVideo}
                    />
                  </div>
              )
          }

          {
            // PHOTO SLIDER
            strapiMainPage?.imgMomentSlider && (
              <div>
                <SliderImages
                  data={strapiMainPage?.imgMomentSlider}
                  positionCenter={false}
                  setOpen={setPopUpGallery}
                  open={popUpGallery}
                  setGalleryElem={setGalleryElem}
                  classAdd="certificate-photo-slider"
                />
              </div>
            )
          }

          {
            // OFFER 2
            strapiGiftPage?.offer2 && (
              <HelpChoosing classAdd="m140" data={strapiGiftPage.offer2} />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}

        {popUpGallery && strapiMainPage?.imgMomentSlider && (
          <GalleryPopup
            open={popUpGallery}
            setOpen={setPopUpGallery}
            galleryElem={galleryElem}
            data={strapiMainPage.imgMomentSlider}
          />
        )}

        {popUpVideo && videoSrc && (
            <VideoPopup
                open={popUpVideo}
                setOpen={setPopUpVideo}
                videoSrc={videoSrc}
                setVideoSrc={setVideoSrc}
            />
        )}

      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiGiftPage {
      offer {
        title
        subtitle
        button
      }
      topItem {
        title
        subtitle
      }
      bottomItem1 {
        title
        subtitle
      }
      bottomItem2 {
        title
        subtitle
      }
      offer2 {
        title
        subtitle
        button
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
    strapiMainPage {
      imgMomentSlider {
        url
      }
      mainVideo {
        url
      }
      mainPoster {
        url
      }
      mainPoster {
        url
      }
    }
  }
`

export default Certificate
